import { Box, ChakraProvider, useMediaQuery } from '@chakra-ui/react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { PROTECTED_PATHS } from '../app/constants';
import SideBar from '../components/Layout/sidebar';
import { Home } from '../pages/home';
import { Navigation } from '../components/Nav';
import { Shop } from '../pages/shop';
import { Errand } from '../pages/errand';
import { Finance } from '../pages/finance';
import { Subscription } from '../pages/subscription';
// import { Withdrawal } from '../pages/withdrawal';
import { ViewCategories } from '../pages/shop/Components/ViewCategories';
import { Offers } from '../pages/offers';
import { AppSetting } from '../pages/appSetting';
import { useEffect, useState } from 'react';
import { Manager } from '../pages/Manager';
import NotificationPage from '../pages/Notification';
import { ChangePassword } from '../pages/changePassword';
import { useDetectAdBlock } from 'adblock-detect-react';
import { ArtisanPage } from '../pages/artisan';

function AuthenticatedUserApp() {
  const [toggleSide, setToggleSide] = useState(false);
  const [showSidBar] = useState(true);

  const [isMobileScreen] = useMediaQuery('(max-width: 600px)');
  const adBlockerAvailable = useDetectAdBlock();

  const {
    HOME,
    SHOP,
    SHOP_CATEGORY,
    ERRAND,
    FINANCE,
    FUND_WALLET,
    SUBSCRIPTION,
    ARTISAN,
    NOTIFICATION,
    OFFERS,
    APP_SETTING,
    MANAGER,
    CHANGE_PASSWORD,
  } = PROTECTED_PATHS;

  const handleToggle = () => {
    setToggleSide((initial) => !initial);
  };

  useEffect(() => {
    if (isMobileScreen) {
      setToggleSide((initial) => !initial);
    }
  }, [isMobileScreen]);

  return (
    <ChakraProvider>
      <Box className='App' display='flex'>
        <Box className={`app-container`}>
          <Box
            className='app-sidebar'
            display={`${toggleSide ? 'none' : 'block'}`}
          >
            {showSidBar ? <SideBar toggle={handleToggle} /> : ''}
          </Box>
          {/* app right */}

          <Box w='100%' h='100%'>
            <Navigation
              toggle={handleToggle}
              sideBarActive={toggleSide}
              showSidBar={showSidBar}
            />

            <Box
              width='100%'
              maxWidth='100%'
              mt={adBlockerAvailable ? '110px' : '70px'}
              padding={['5px', '10px', '20px']}
            >
              <Switch>
                <Route path={HOME} exact component={Home} />
                <Route path={SHOP} exact component={Shop} />
                <Route path={ERRAND} exact component={Errand} />
                <Route path={FINANCE} exact component={Finance} />
                <Route path={SUBSCRIPTION} exact component={Subscription} />
                {/* <Route path={WITHDRAWAL} exact component={Withdrawal} /> */}
                <Route path={SHOP_CATEGORY} exact component={ViewCategories} />
                <Route path={OFFERS} exact component={Offers} />
                <Route path={NOTIFICATION} exact component={NotificationPage} />
                <Route path={APP_SETTING} exact component={AppSetting} />
                <Route path={MANAGER} exact component={Manager} />
                <Route path={FUND_WALLET} exact component={Finance} />
                <Route path={ARTISAN} exact component={ArtisanPage} />
                <Route
                  path={CHANGE_PASSWORD}
                  exact
                  component={ChangePassword}
                />
                <Redirect from='/*' to={HOME} />
              </Switch>
            </Box>
          </Box>
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default AuthenticatedUserApp;
