import http, { AUTH_ROUTES } from '../../../services/api';
import { errorNotifier, successNotifier } from '../../../components/NotificationHandler';

export const changePassword = async (userId, payload, setLoading) => {
  try {
    await http.put(AUTH_ROUTES.CHANGE_PASSWORD(userId), payload);
    setLoading(false);
    successNotifier('Changed password successfully');
  } catch (e) {
    errorNotifier(e.response?.data?.result);
    setLoading(false);
  } finally {
    setLoading(false);
  }
};

export const fetchArtisanData = async (setLoading, setData, setCounts) => {
  setLoading(true);
  try {
    const response = await http.get(
      AUTH_ROUTES.GET_ARTISAN
    );
    const data = response.data.result.data;

    setData(data);
    setCounts({
      total: data.length,
      subscribed: data.filter((item) => item.subscription).length,
      active: data.filter((item) => item.approved).length,
      inactive: data.filter((item) => !item.approved).length,
    });
  } catch (error) {
    console.error("Error fetching artisan data:", error);
  } finally {
    setLoading(false);
  }
};

export const toggleApprovalStatus = async (artisanId, approved, setData, setLoading, setCounts) => {
  try {
    await http.put(
      AUTH_ROUTES.ARTISAN_APPROVAL(artisanId),
      { approved: !approved }
    );

    // Update the local state to reflect the change
    setData((prevData) =>
      prevData.map((artisan) =>
        artisan.artisanId === artisanId
          ? { ...artisan, approved: !approved }
          : artisan
      )
    );

    // Fetch updated data
    fetchArtisanData(setLoading, setData, setCounts);
  } catch (error) {
    console.error("Error updating approval status:", error);
  }
};
