import React, { useState, useEffect } from "react";
import {
  Box,
  VStack,
  Text,
  Button,
  SimpleGrid,
  Switch,
  Input,
  Select,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import axios from "axios";
import { CustomTable } from "../../components/customized table/CustomTable";
import FullPageLoader from "../../components/full-page-loader";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import {fetchArtisanData, toggleApprovalStatus} from "./service";


export const ArtisanPage = () => {
  const [loading, setLoading] = useState(true);
  const [artisanData, setArtisanData] = useState([]);
  const [counts, setCounts] = useState({
    total: 0,
    subscribed: 0,
    active: 0,
    inactive: 0,
  });
  const [filteredData, setFilteredData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filterCriteria, setFilterCriteria] = useState("all");

  const [selectedArtisan, setSelectedArtisan] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    fetchArtisanData(setLoading, setArtisanData, setCounts);
  }, []);

  useEffect(() => {
    let data = artisanData;

    // Apply Filter
    if (filterCriteria !== "all") {
      data = data.filter((item) => {
        if (filterCriteria === "subscribed") return item.subscription;
        if (filterCriteria === "not_subscribed") return !item.subscription;
        if (filterCriteria === "active") return item.approved;
        if (filterCriteria === "inactive") return !item.approved;
        return true;
      });
    }

    // Apply Search
    if (searchInput) {
      data = data.filter((item) =>
        ["name", "phoneNumber", "email", "address", "skillType"]
          .some((key) => item[key]?.toString().toLowerCase().includes(searchInput.toLowerCase()))
      );
    }

    setFilteredData(data);
  }, [searchInput, filterCriteria, artisanData]);

  const handleView = (artisan) => {
    setSelectedArtisan(artisan);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedArtisan(null);
    setModalOpen(false);
  };

  const artisanColumns = [
    { Header: "S/N", accessor: "id", Cell: ({ row }) => Number(row.id) + 1 },
    { Header: "Name", accessor: "name" },
    { Header: "Phone Number", accessor: "phoneNumber" },
    { Header: "Skill Type", accessor: "skillType" },
    { Header: "Expertise Level (/10)", accessor: "expertise" },
    {
      Header: "Active Status",
      Cell: ({ row }) => (
        <Switch
          colorScheme="green"
          isChecked={row.original.approved}
          onChange={() =>
            toggleApprovalStatus(
              row.original._id,
              row.original.approved,
              setArtisanData,
              setLoading,
              setCounts
            )
          }
        />
      ),
    },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <Button size="sm" bg="#614285" color="#fff" onClick={() => handleView(row.original)}>
          View
        </Button>
      ),
    },
  ];

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box>
      <SimpleGrid color="#fff" columns={{ base: 2, md: 4 }} spacing={5} mb={6}>
        <Box bg="#614285" p={5} borderRadius="md" textAlign="center">
          <Text fontSize="xl" fontWeight="bold">{counts.total}</Text>
          <Text>Total Users</Text>
        </Box>
        <Box bg="#426185" p={5} borderRadius="md" textAlign="center">
          <Text fontSize="xl" fontWeight="bold">{counts.subscribed}</Text>
          <Text>Subscribed</Text>
        </Box>
        <Box bg="#618542" p={5} borderRadius="md" textAlign="center">
          <Text fontSize="xl" fontWeight="bold">{counts.active}</Text>
          <Text>Active</Text>
        </Box>
        <Box bg="#fc8181" p={5} borderRadius="md" textAlign="center">
          <Text fontSize="xl" fontWeight="bold">{counts.inactive}</Text>
          <Text>Inactive</Text>
        </Box>
      </SimpleGrid>

      <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
        <Input
          placeholder="Search..."
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          width="300px"
          borderColor={"#614285"}
          borderWidth={"2px"}

        />
        <Select
          placeholder="Filter by"
          value={filterCriteria}
          onChange={(e) => setFilterCriteria(e.target.value)}
          width="200px"
          borderColor={"#614285"}
          borderWidth={"2px"}

        >
          <option value="all">All</option>
          <option value="subscribed">Subscribed</option>
          <option value="not_subscribed">Not Subscribed</option>
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </Select>
      </Box>

      <Box bg="#fff" p={5}>
        <CustomTable COLUMNS={artisanColumns} DATA={filteredData} />
      </Box>

      {selectedArtisan && (
        <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader bg="#fc8181" color="#fff">
              Artisan Details
            </ModalHeader>
            <ModalCloseButton color="#fff" />
            <ModalBody>
              <img
                src={selectedArtisan.image}
                alt={selectedArtisan.name}
                className="img-fluid"
                style={{
                  maxWidth: "80px",
                  height: "80px",
                  borderRadius: "50%",
                  margin: "0 auto",
                  display: "block"
                }}
              />
              <VStack align="start" spacing={3}>
                <Text>
                  <strong>ID:</strong> {selectedArtisan.artisanId}
                </Text>
                <Text>
                  <strong>Name:</strong> {selectedArtisan.name}
                </Text>
                <Text>
                  <strong>Email:</strong> {selectedArtisan.email}
                </Text>
                <Text>
                  <strong>Phone Number:</strong> {selectedArtisan.phoneNumber}
                </Text>
                <Text>
                  <strong>Address:</strong> {selectedArtisan.address}
                </Text>
                <Text>
                  <strong>Skill Type:</strong> {selectedArtisan.skillType}
                </Text>
                <Text>
                  <strong>Expertise Level:</strong> {selectedArtisan.expertise}
                </Text>
                <Text>
                  <strong>Subscription:</strong>{" "}
                  {selectedArtisan.subscription ? "Yes" : "No"}
                </Text>
                <Text>
                  <strong>Approved:</strong>{" "}
                  {selectedArtisan.approved ? "Yes" : "No"}
                </Text>
                <Text>
                  <strong>Created At:</strong>{" "}
                  {dayjs(selectedArtisan.createdAt).format("DD-MMM-YYYY")}
                </Text>
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default ArtisanPage;
