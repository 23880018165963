import { PROTECTED_PATHS } from '../../../../app/constants';
import { BiHomeAlt } from 'react-icons/bi';
import { AiOutlineSetting, AiOutlineShop, AiOutlineInbox, AiOutlineSync } from 'react-icons/ai';
import { IoManOutline } from 'react-icons/io5';
import { RiCoinsLine } from 'react-icons/ri';
// import { BsFileArrowDown, BsSignpostSplit } from 'react-icons/bs';
import { GiPriceTag } from 'react-icons/gi';
import { VscBellDot } from 'react-icons/vsc';
const {
  HOME,
  SHOP,
  ERRAND,
  FINANCE,
  SUBSCRIPTION,
  ARTISAN,
  NOTIFICATION,
  OFFERS,
  MANAGER,
  APP_SETTING,
} = PROTECTED_PATHS;

export const SECURITY_NAV_ITEMS = [
  { title: 'Home', to: HOME, icon: BiHomeAlt },
  { title: 'Shop', to: SHOP, icon: AiOutlineShop },
  { title: 'Artisan', to: ARTISAN, icon: AiOutlineSetting },
  { title: 'Errands', to: ERRAND, icon: AiOutlineInbox },
  { title: 'Finances', to: FINANCE, icon: RiCoinsLine },
  { title: 'Subscriptions', to: SUBSCRIPTION, icon: AiOutlineSync },
  // { title: 'Withdrawals', to: WITHDRAWAL, icon: BsFileArrowDown },
  { title: 'Notifications', to: NOTIFICATION, icon: VscBellDot },
  { title: 'Offers', to: OFFERS, icon: GiPriceTag },
  { title: 'Managers', to: MANAGER, icon: IoManOutline },
  { title: 'App Settings', to: APP_SETTING, icon: AiOutlineSetting },
];
