export const getComponentTitle = pathname => {
	switch (pathname) {
		case "/home":
			return "Home"

		case "/shop":
			return "Shop"

		case "/shop/category":
			return "Shop"

		case "/errands":
			return "Errands"

		case "/finances":
			return "Finances"

		case "/finances/fund-wallet":
			return "Finances"

		case "/subscriptions":
			return "Subscriptions"

		case "/withdrawal":
			return "Withdrawal"

		case "/notifications":
			return "Notifications"

		case "/offers":
			return "Offers"

		case "/manager":
			return "Manager"

		case "/app-setting":
			return "App Settings"

		case "/artisan":
			return "Artisan"

		default:
			return "Welcome"
	}
}
