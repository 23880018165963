export const AUTH_ROUTES = {
  REGISTER: '/register',
  LOGIN: '/login',
  ADMIN_LOGIN: '/admin/login',
  ADD_FUNDS: '/admin/add-fund',
  USER: '/user',
  ADMIN: '/admin',
  CREATE_MANAGER: '/admin/create-manager',
  GET_ESTATE_INFO: (userId) => `admin/estate-information/${userId}`,
  CHANGE_ESTATE_INFO: `admin/create-estate-information/`,
  CHANGE_PASSWORD: (userId) => `admin/update-admin/${userId}`,
  DELETE_MANAGER: (userId, rowId) => `/admin/delete-manager/${userId}/${rowId}`,
  BAN_MANAGER: (userId, rowId) => `/admin/manager/${userId}/${rowId}`,
  UNBAN_MANAGER: (userId, rowId) => `admin/manager/${userId}/${rowId}`,
  UPDATE_MANAGER: (userId, rowId) => `/admin/manager/${userId}/${rowId}`,

  CREATE_SECURITY: '/admin/create-security',
  DELETE_SECURITY: (userId, rowId) =>
    `/admin/delete-security/${userId}/${rowId}`,
  BAN_SECURITY: (userId, rowId) => `/admin/security/${userId}/${rowId}`,
  UNBAN_SECURITY: (userId, rowId) => `admin/security/${userId}/${rowId}`,
  UPDATE_SECURITY: (userId, rowId) => `/admin/security/${userId}/${rowId}`,

  NETWORK: '/network',
  SUBSCRIPTION: '/subscriptions?sort=desc',
  FUND_WALLET: '/fund-wallet',

  // offer
  GET_OFFER: (userId) => `/admin/offers/${userId}?sort=desc&limit=20&skip=0`,
  DELETE_OFFER: (userId, offerId) => `/admin/offer/${userId}/${offerId}`,
  CREATE_OFFER: '/admin/create-offer',
  EDIT_OFFER: (adminId, rowId) => {
    return `/admin/offer/${adminId}/${rowId}`;
  },
  GET_OFFER_PARTICIPANTS: (userId, rowId) =>
    `/admin/get-offer-participants/${userId}/${rowId}?sort=desc`,

  ADD_ERRAND_TYPE: '/admin/create-errand-category',
  ADD_ERRAND_BOY: '/admin/create-errand-boy',
  GET_SHOP: ({ sort = 'desc', skip, limit, status }) =>
    `/admin/adverts?sort=${sort}&skip=${skip}&limit=${limit}${
      status ? `&status=${status}` : ''
    } `,
  ASSIGN_ERRAND: (userId, id) => `/admin/errands/${userId}/${id}`,

  GET_ERRANDS: ({ userId, skip, limit, status }) =>
    `/admin/errands/${userId}?sort=desc${skip ? `&skip=${skip}` : ''}${
      limit ? `&limit=${limit}` : ''
    }${status ? `&status=${status}` : ''}`,
  EDIT_SHOP: (userId, rowId) =>
    `/admin/update-advert-status/${userId}/${rowId}`,
  SEND_MESSAGE: '/admin/send-message',
  DELETE_SHOP: (userId, rowId) => `/admin/advert/${userId}/${rowId}`,
  GET_SHOP_CATEGORY: (userId, page, limit) =>
    `/admin/advert-categories/${userId}?sort=desc&skip=${page}&limit=${limit}`,

  CREATE_SHOP_CATEGORY: `/admin/create-advert-category`,
  UPDATE_CATEGORY: (userId, rowId) =>
    `/admin/advert-category/${userId}/${rowId}?sort=desc`,
  DELETE_SHOP_CATEGORY: (userId, rowId) =>
    `/admin/advert-category/${userId}/${rowId}`,
  GET_ERRAND_BOYS: ({ userId, skip, limit, status }) =>
    `/admin/errand-boys/${userId}?sort=desc&skip=${skip}&limit=${limit}${
      status ? `&status=${status}` : ''
    }`,

  BAN_ERRAND_BOY: (userId, errandBoyId) =>
    `/admin/errand-boys/${userId}/${errandBoyId}`,

  UNBAN_ERRAND_BOY: (userId, errandBoyId) =>
    `/admin/errand-boys/${userId}/${errandBoyId}`,
  UPDATE_ERRANDS: (userId, rowId) => `/admin/errands/${userId}/${rowId}`,

  GET_DASHBOARD: (userId) => `/manager/analytics/${userId}`,
  GET_TRANSACTIONS: (userId, page, limit, transactionType) =>
    `/admin/transactions/${userId}?sort=desc&skip=${page}&limit=${limit}${
      transactionType ? `&transactionType=${transactionType}` : ''
    } `,

  GET_FUNDWALLET: (userId, page, limit) =>
    `/admin/funds/${userId}?sort=desc&skip=${page}&limit=${limit}`,

  GET_TENANT_PROFILE: (userId, tenantId) =>
    `/admin/tenant-profile/${userId}/${tenantId}`,
  GET_ERRANDS_TYPES: ({ userId, page, limit }) =>
    `/admin/errand-categories/${userId}?&limit=${limit}&skip=${page}&sort=desc`,
  EDIT_ERRAND_TYPE: (userId, errandId) =>
    `/admin/errand-category/${userId}/${errandId}`,
  EDIT_ERRAND_BOY: (userId, errandBoyId) =>
    `/admin/errand-boys/${userId}/${errandBoyId}`,
  GET_ERRAND_SUBSCRIPTION: (userId, page, limit) =>
    `/admin/errand-packages/${userId}?sort=desc&limit=${limit}&skip=${page}`,

  EDIT_ERRAND_SUBSCRIPTION: (userId, errandId) =>
    `/admin/errand-package/${userId}/${errandId}`,
  DELETE_SUBSCRIPTION: (userId, subId) =>
    `/admin/errand-package/${userId}/${subId}`,
  CREATE_ERRAND_SUBSCRIPTION: '/admin/errand-package',

  CANCEL_ERRAND: (userId, id) => `/admin/cancel-errand/${userId}/${id}`,
  DELETE_ERRAND_BOY: (userId, errandBoyId) =>
    `/admin/errand-boys/${userId}/${errandBoyId}`,

  GET_SUBSCRIPTIONS: (userId, page, limit) =>
    `/admin/app-subscription/${userId}?skip=${page}&limit=${limit}&sort=desc`,

  GET_MANAGERS: (userId, skip, limit, status) =>
    `/admin/manager/${userId}?sort=desc&skip=${skip}&limit=${limit}${
      status ? `&status=${status}` : ''
    }`,
  GET_SECURITIES: (userId, skip, limit, status) =>
    `/admin/securities/${userId}?sort=desc&skip=${skip}&limit=${limit}${
      status ? `&status=${status}` : ''
    }`,

  GET_OFFERS: (userId, status) =>
    `/admin/offers/${userId}?sort=desc&limit=10000000&status=${status}`,

  SEND_MESSAGE_TO_PARTICIPANTS: `admin/send-message/`,
  GET_TOP_OFFERS: (userId) =>
    `/admin/offers/${userId}?sort=desc&limit=5&skip=0`,

  GET_NOTIFICATIONS: 'notifications/admin',

  GET_WITHDRAWALS: ({ userId, sort = 'desc', limit, page, status }) =>
    `wallet/withdrawal-request/${userId}?sort=${sort}&limit=${limit}&skip=${page}${
      status ? `&status=${status}` : ''
    }`,
  APPROVE_WITHDRAWALS: (userId, tenantId) =>
    `/wallet/withdrawal-request/${userId}/${tenantId}`,
  DECLINE_WITHDRAWALS: (userId, tenantId) =>
    `/wallet/withdrawal-request/${userId}/${tenantId}`,

  //subscriptions
  GET_ENERGY_SUBSCRIPTION: (userId) =>
    `/wallet/electricity-plans/${userId}?limit=100`,

  // GET_DATA_SUBSCRIPTION: (userId) => `/wallet/data-plans/${userId}`,
  GET_DATA_SUBSCRIPTION: (userId) => `/admin/dataplan/${userId}?limit=100`,

  // UPDATE_DATA_SUBSCRIPTION: '/admin/data-subscription',
  UPDATE_DATA_SUBSCRIPTION: (userId, dataSubId) =>
    `/admin/dataplan/${userId}/${dataSubId}`,
  SYNC_DATA_PLAN: (userId) => `/admin/sync-dataplan/${userId}`,

  // GET_CABLE_SUBSCRIPTION: (userId) => `/wallet/cable-plans/${userId}`,
  GET_CABLE_SUBSCRIPTION: (userId) => `/admin/cableplan/${userId}?limit=100`,

  // UPDATE_CABLE_SUBSCRIPTION: '/admin/cable-subscription',
  UPDATE_CABLE_SUBSCRIPTION: (userId, cableSubId) =>
    `/admin/cableplan/${userId}/${cableSubId}`,

  UPDATE_ENERGY_SUBSCRIPTION: '/admin/energy-subscription',

  //banner
  GET_BANNER: (userId) => `/admin/banner/${userId}`,
  DELETE_BANNER: (userId, bannerId) => `/admin/banner/${userId}/${bannerId}`,
  CREATE_BANNER: '/admin/banner',
  GET_ARTISAN: '/artisan',
  ARTISAN_APPROVAL: (artisanId) => `/artisan/${artisanId}`,

};
