import React from "react";

import { Flex, Box, Text, Center } from "@chakra-ui/react";
import { AiOutlineInfoCircle } from "react-icons/ai";

const NotificationCard = ({ title, description }) => {
  return (
    <Flex p="10px" mb={2}>
      <Center w="15%" mr={["10px", "0"]}>
        <AiOutlineInfoCircle color="#acac0c" fontSize="3em" />
      </Center>
      <Box bg="#FFFFFF" textAlign="left" w="100%" p="10px">
        <Text
          color="#00000099"
          fontWeight="bold"
          mb="10px"
          fontSize={["1em", "1.4em"]}
        >
          {title}
        </Text>
        <Text color="#585858" fontSize={".8em"} fontWeight="400">
          {description}
        </Text>
      </Box>
    </Flex>
  );
};

export default NotificationCard;
